<template>
  <div id="container_wrapper">
    <div id="container">
      <div id="close-top">
        <a class="link" href="javascript:self.close();" title="Close"
        >Close this window</a
        >
      </div>
      <br />
      <div id="header"></div>
      <div id="info">
        <h1>Debt Reduction Team Terms of Use</h1>
        <h2>LAST UPDATED: FEB 01, 2020</h2>
        <p>
          Debt Reduction Team refers to (the "Website," "we," "our," or "us").
        </p>
        <p>
          <strong
          >Please read these Terms of Service (the "Agreement") carefully. Your
            use of the Site constitutes your agreement to be bound by this
            Agreement.</strong
          >
          The <strong>"Site"</strong> means all the content, services and products
          available on the websites, applications, emails, widgets or other media
          through which you accessed this Agreement.
        </p>
        <p>
          <strong
          >THIS AGREEMENT CONTAINS AN AGREEMENT TO ARBITRATE ALL CLAIMS (PLEASE
            SEE SECTION 17). EXCEPT IF YOU OPT-OUT AND EXCEPT FOR CERTAIN TYPES OF
            DISPUTES DESCRIBED IN THE ARBITRATION SECTION BELOW, YOU AGREE THAT
            DISPUTES BETWEEN YOU AND QS WILL BE RESOLVED BY BINDING, INDIVIDUAL
            ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION
            LAWSUIT OR CLASS-WIDE ARBITRATION.</strong
          >
        </p>
        <p><strong>Table of Consents:</strong></p>
        <p>
          1.&nbsp;&nbsp;&nbsp;&nbsp;<a href="#1" class="nocolor"
        >Modification of Terms</a
        ><br />
          2.&nbsp;&nbsp;&nbsp;&nbsp;<a href="#2" class="nocolor"
        >Your Access and Use of the Site</a
        ><br />
          3.&nbsp;&nbsp;&nbsp;&nbsp;<a href="#3" class="nocolor"
        >Information you submit through the Site</a
        ><br />
          4.&nbsp;&nbsp;&nbsp;&nbsp;<a href="#4" class="nocolor"
        ><strong
        >AUTHORIZATION TO OBTAIN A CREDIT REPORT AND CREDIT SCORE</strong
        ></a
        ><br />
          5.&nbsp;&nbsp;&nbsp;&nbsp;<a href="#5" class="nocolor"
        >Services and Content Offered on the Site</a
        ><br />
          6.&nbsp;&nbsp;&nbsp;&nbsp;<a href="#6" class="nocolor"
        >Rules of Conduct</a
        ><br />
          7.&nbsp;&nbsp;&nbsp;&nbsp;<a href="#7" class="nocolor"
        >Jurisdictional Issues</a
        ><br />
          8.&nbsp;&nbsp;&nbsp;&nbsp;<a href="#8" class="nocolor"
        >Links to third party sites</a
        ><br />
          9.&nbsp;&nbsp;&nbsp;&nbsp;<a href="#9" class="nocolor"
        >Registration; Usernames and Passwords</a
        ><br />
          10.&nbsp;&nbsp;<a href="#10" class="nocolor">Submissions</a><br />
          11.&nbsp;&nbsp;<a href="#11" class="nocolor">Proprietary Rights</a
        ><br />
          12.&nbsp;&nbsp;<a href="#12" class="nocolor"
        ><strong>CONSENT TO ELECTRONIC COMMUNICATIONS</strong></a
        ><br />
          13.&nbsp;&nbsp;<a href="#13" class="nocolor">Disclaimer of Warranties</a
        ><br />
          14.&nbsp;&nbsp;<a href="#14" class="nocolor">Limitation of Liability</a
        ><br />
          15.&nbsp;&nbsp;<a href="#15" class="nocolor">Indemnification</a><br />
          16.&nbsp;&nbsp;<a href="#16" class="nocolor">Release</a><br />
          17.&nbsp;&nbsp;<a href="#17" class="nocolor"
        ><strong>ARBITRATION</strong></a
        ><br />
          18.&nbsp;&nbsp;<a href="#18" class="nocolor"
        >Claims of Copyright Infringement</a
        ><br />
          19.&nbsp;&nbsp;<a href="#19" class="nocolor">Governing Law</a><br />
        </p>
        <p></p>
        <ol>
          <li>
            <strong><a name="1" class="nocolor">Modification of Terms.</a></strong
            ><a name="1" class="nocolor">
            We reserve the right to modify this Agreement from time to time.
            Your use of the Site after such a modification will constitute your
            acceptance of those changes. We may notify you of any such changes
            by any reasonable means, including by posting the revised version of
            this Agreement to the Site. You can determine when we last changed
            this Agreement by referring to the "LAST UPDATED" date above.</a
          >
          </li>
          <li>
            <strong
            ><a name="2" class="nocolor"
            >Your Access to and Use of the Site.</a
            ></strong
            ><a name="2" class="nocolor"
          >Your access to and use of the Site may be interrupted from time to
            time, including due to technical malfunctions, periodic updating and
            repairs. We may, at any time, modify or discontinue all or part of
            the Site, charge, modify or waive any fees required to use the Site,
            or offer opportunities to some or all Site users. We may, in our
            discretion and without notice, suspend or terminate your access to
            the Site and to any services offered on the Site, and may remove and
            delete any content you have provided if we believe that your conduct
            or content violates or is inconsistent with this Agreement or its
            intent, that your conduct is disruptive, or that you have violated
            the law or the rights of any third parties.</a
          >
          </li>
          <li>
            <strong
            ><a name="3" class="nocolor"
            >Information you submit through the Site.</a
            ></strong
            ><a name="3" class="nocolor">
            To use certain services on the Site, you may be required to submit
            information to us, including personally identifying information. Our
            use and disclosure of any information you submit through the Site is
            governed by this Agreement and our </a
          ><a href="#">PRIVACY POLICY</a>. You agree that all information you
            provide to us is true, accurate and complete and that you will not
            misrepresent your identify, impersonate any third party or enter
            information on behalf of any third party. You also agree to update
            such information regularly to keep it current.
          </li>
          <li>
            <strong
            ><a name="4" class="nocolor"
            >Authorization to Obtain a Credit Report and Credit Score.</a
            ></strong
            ><a name="4" class="nocolor">
            You understand and agree that by submitting your request to be
            matched with lenders, you are providing written instruction under
            the Fair Credit Reporting Act for our and the lenders with whom you
            are matched to obtain your consumer credit report, credit score and
            other information from a consumer reporting agency in order to
            provide you with quotes and verify your identity. The lenders with
            whom you are matched may obtain your credit report, credit score and
            other information to make a final decision regarding extending a
            loan to you.</a
          >
          </li>
          <li>
            <strong
            ><a name="5" class="nocolor"
            >Services and Content Offered on the Site.</a
            ></strong
            ><a name="5" class="nocolor">
            The Site provides you with access to certain services, such as
            referrals to products, services and service providers that may be of
            interest to you (the "<strong>Site Services</strong>"), and to
            certain content provided by us or by third parties (the "<strong
          >Site Content</strong
          >"). <strong>PLEASE READ THE FOLLOWING CAREFULLY:</strong>
            <p>
              <u>Requests to be Matched</u>. We may provide you with the
              opportunity to submit requests (each, a
              "<strong>Request</strong>") for information and to be matched with
              mortgage lenders, personal loan lenders or other service providers
              ("<strong>Service Providers</strong>"). A Request is not an
              application for credit or a request for a loan pre-qualification.
              Rather, a Request is an inquiry to be matched with Service
              Providers. The Site acts solely as a paid marketing lead
              generator. You understand that we are not a broker, lender,
              creditor for or issuer of the products featured on the Site. We do
              not make any decisions regarding the products and services offered
              by the Service Providers and does not make any loan or credit
              decisions. We are not your agent, nor is it the agent of any
              Service Provider with respect to your request for financial
              offerings. Ww may receive a marketing lead generation fee from the
              Service Providers.
            </p>
            <p>
              <u>No guarantee of loan or credit</u>. We cannot and do not
              guarantee that you will be matched to any Service Provider, that
              any Service Provider will make a loan or offer to you, or that any
              program terms including any specific loan terms or rates, offered
              by the Service Providers are the best terms or lowest rates
              available in the market. A Service Provider's offer may be subject
              to market conditions, approval and qualification. The rates and
              fees you actually achieve may be higher or lower depending on your
              complete credit and financial profile. Your ability to secure any
              loan or consummate any transaction with any Service Provider is
              solely a matter to be resolved between you and such Service
              Provider. You may have to complete an application with, and pay an
              application fee to, the Service Provider before they extend an
              offer to you or provide that Service Provider with additional
              information to enable them to further verify your income.
            </p>
            <p>
              <u>Transfer of your information</u>. In submitting a Request to
              us, both we and the Service Providers may ask for your name,
              Social Security number, address, telephone number, date of birth
              and other important information to allow us and the Service
              Providers to verify your identity and match you to offers. In
              submitting a Request to be matched on the Site, you agree to allow
              us to transfer all of the information that you provide in
              connection with your Request to third parties, including Service
              Providers, whether or not you have specifically selected such
              Service Provider. If any Service Provider(s) wish to provide
              information to you in connection with your Request, you will be
              contacted regarding the offerings and related pricing, and the
              Service Provider(s) may keep your Request information and any
              other information provided by us in connection with your Request
              whether or not they offer you a product or service. Please notify
              the Service Provider(s) directly if you no longer wish to receive
              communications from them.
            </p>
            <p>
              In submitting a Request to be matched with Service Providers, you
              further expressly authorize any Service Providers with whom I am
              matched to share among its affiliates, loan services, and bank
              partners any transaction history related to your financial
              products or services received or serviced through such Service
              Provider for the purpose of evaluating me for credit.
            </p>
            <p>
              <u>No endorsements or recommendations</u>. In working with Service
              Providers, we seek to work with companies that are reputable and
              professional; however, we strongly recommend that you perform your
              own due diligence on each potential Service Provider prior to
              selecting and/or entering into any type of agreement or other
              arrangement with any particular Service Provider. In addition,
              once we refer your Request to the Service Providers, We have no
              further involvement in any transactions that occur between you and
              the Service Provider(s). We neither recommends nor endorses any
              specific products, opinions, or other Site Content that may be
              made available through or mentioned on the Site.
            </p>
            <p>
              WE ARE NOT RESPONSIBLE OR LIABLE FOR ANY SERVICE PROVIDER'S ACTS
              OR OMISSIONS INCLUDING FOR ANY QUOTES OR SERVICES THAT ANY SUCH
              SERVICE PROVIDER MAY PROVIDE, FOR ANY SERVICE PROVIDER'S
              CONTACTING OR FAILURE TO CONTACT YOU, FOR ANY SERVICE PROVIDER'S
              PERFORMANCE OR FAILURE TO PERFORM ANY SERVICES, OR FOR ANY
              AGREEMENT OR TRANSACTION BETWEEN YOU AND ANY SERVICE PROVIDER.
            </p>
            <p>
              <u>No substitute for professional advice</u>. We provide all Site
              Content and Site Services solely for your convenience, and such
              Site Content and Site Services (i) are not intended as a
              substitute for professional advice; (ii) should not be construed
              as the provision of advice or recommendations; and (iii) should
              not be relied upon as the basis for any financial decision or
              action. We are not responsible for the accuracy or reliability of
              any Site Content or the Site Services. Site Content is not
              exhaustive and should not be considered complete or up-to-date. It
              is your responsibility to evaluate the accuracy, completeness or
              usefulness of any Site Content or Site Services available through
              the Site. The relationship between you and us is not a
              professional or similar relationship; always seek the advice of a
              qualified professional with respect to any questions that you may
              have, and never disregard professional advice or delay in seeking
              it because of something that you have read on the Site.
            </p>
          </a>
          </li>
          <li>
            <strong><a name="6" class="nocolor">Rules of Conduct.</a></strong
            ><a name="6" class="nocolor">
            <ol type="a">
              <li>You must be at least 18 years old to use the Site.</li>
              <li>
                You must not post violent, nude, partially nude, discriminatory,
                unlawful, infringing, hateful, pornographic or sexually
                suggestive photos or other content via the Site.
              </li>
              <li>
                You are responsible for any activity that occurs through your
                account and you agree you will not sell, transfer, license or
                assign your account, followers, username, or any account rights.
                With the exception of people or businesses that are expressly
                authorized to create accounts on behalf of their employers or
                clients, we prohibit the creation of and you agree that you will
                not create an account for anyone other than yourself. You also
                represent that all information you provide or provided to us
                upon registration and at all other times will be true, accurate,
                current and complete and you agree to update your information as
                necessary to maintain its truth and accuracy.
              </li>
              <li>
                You must not create accounts with the Site through unauthorized
                means, including by using an automated device, script, bot,
                spider, crawler or scraper.
              </li>
              <li>
                You must not solicit, collect or use the login credentials of
                other Site users.
              </li>
              <li>
                You must not use the Site to defame abuse, harass, stalk,
                threaten or otherwise violate the legal rights of others,
                including others' privacy rights or rights of publicity, or
                harvest or collect personally identifiable information about
                other users of the Site.
              </li>
              <li>
                You must not use the Site for any illegal or unauthorized
                purpose. You agree to comply with all laws, rules and
                regulations (for example, federal, state, local and provincial)
                applicable to your use of the Site, including copyright laws.
              </li>
              <li>
                You are solely responsible for your conduct and any data, text,
                files, information, usernames, images, graphics, photos,
                profiles, audio and video clips, sounds, musical works, works of
                authorship, applications, links and other content or materials
                that you submit, post or display on or via the Site.
              </li>
              <li>
                You must not interfere or disrupt the Site or servers or
                networks connected to the Site Services, including by
                transmitting any worms, viruses, spyware, malware or any other
                code of a destructive or disruptive nature. You may not inject
                content or code or otherwise alter or interfere with the way any
                Site page is rendered or displayed in a user's browser or
                device.
              </li>
              <li>
                You must not use any robot, spider, site search/retrieval
                application or other manual or automatic device to retrieve,
                index, "scrape," "data mine" or in any way gather Site content
                or reproduce or circumvent the navigational structure or
                presentation of the Site without our express prior written
                consent. Notwithstanding the immediately foregoing sentence (but
                subject to the other items listed above), we grant the operators
                of public search engines permission to use spiders to copy
                materials from the Site for the sole purpose of and solely to
                the extent necessary for creating publicly-available searchable
                indices of such materials, but not caches or archives of such
                materials. We reserve the right to revoke these exceptions,
                either generally or in specific cases, at any time.
              </li>
              <li>
                You must not restrict or inhibit any other person from using the
                Site (including by hacking or defacing any portion of the Site).
              </li>
              <li>
                You must not reproduce, duplicate, copy, sell, resell or
                otherwise exploit for any commercial purposes, any portion of,
                use of, or access to the Site.
              </li>
              <li>
                Except as expressly permitted by applicable law, you must not
                modify, adapt, translate, reverse engineer, decompile or
                disassemble any portion of the Site.
              </li>
              <li>
                You must not remove any copyright, trademark or other
                proprietary rights' notices from the Site or materials
                originating from the Site.
              </li>
              <li>
                You must not frame or mirror any part of the Site without our
                express prior written consent.
              </li>
              <li>
                You must not create a database by systematically downloading and
                storing all or any Site content.
              </li>
            </ol>
          </a>
          </li>
          <a name="6" class="nocolor"> </a>
          <li>
            <a name="6" class="nocolor"><strong></strong></a
            ><strong
          ><a name="7" class="nocolor">Jurisdictional Issues.</a></strong
          ><a name="7" class="nocolor">
            The Site is controlled and/or operated from the United States, and
            is not intended to subject us to non-U.S. jurisdiction or laws,
            except as otherwise expressly stated in this Agreement. The Site may
            not be appropriate or available for use in some jurisdictions
            outside of the United States. In choosing to access the Site, you do
            so on your own initiative and at your own risk, and you are
            responsible for complying with all local laws, rules and
            regulations, including any export controls, embargoes or other rules
            and regulations restricting exports. We may limit the Site's
            availability, in whole or in part, to any person, geographic area or
            jurisdiction we choose, at any time and in our sole discretion.</a
          >
          </li>
          <a name="7" class="nocolor"> </a>
          <li>
            <a name="7" class="nocolor"><strong></strong></a
            ><strong
          ><a name="8" class="nocolor">Links to third party sites.</a></strong
          ><a name="8" class="nocolor">
            We may provide links to external websites or resources for your
            convenience and reference only. We do not endorse and is not
            responsible for the availability of these external websites or
            resources or for any content, advertising, products, or other
            materials on or available from such external websites or resources.
            Your use of and dealings with the owners or operators of these
            external websites or resources are at your own risk. You cannot make
            any claim against us, or its Service Providers arising out of your
            use of external websites or resources.</a
          >
          </li>
          <a name="8" class="nocolor"> </a>
          <li>
            <a name="8" class="nocolor"><strong></strong></a
            ><strong
          ><a name="9" class="nocolor"
          >Registration; Usernames and Passwords.</a
          ></strong
          ><a name="9" class="nocolor">
            You may be required to register with us in order to access certain
            services or areas of the Site. With respect to any such
            registration, we may refuse to grant you, and you may not use, a
            username (or email address) that violates the intellectual property
            or other rights of any person; that is offensive; or that we rejects
            for any other reason in its sole discretion. Your username and
            password are for your personal use only, and not for use by any
            other person. You are responsible for maintaining the
            confidentiality of any password you may use to access the Site, and
            agree not to transfer your password or username, or lend or
            otherwise transfer your use of or access to the Site, to any third
            party. You are fully responsible for all interaction with the Site
            that occurs in connection with your password or username. You agree
            to immediately notify us of any unauthorized use of your password or
            username or any other breach of security related to your account or
            the Site, and to ensure that you "log off"/exit from your account
            with the Site (if applicable) at the end of each session. We are not
            liable for any loss or damage arising from your failure to comply
            with any of the foregoing obligations.</a
          >
          </li>
          <a name="9" class="nocolor"> </a>
          <li>
            <a name="9" class="nocolor"><strong></strong></a
            ><strong><a name="10" class="nocolor">Submissions.</a></strong
          ><a name="10" class="nocolor">
            For purposes of clarity, you retain ownership of any information,
            content and/or materials that you submit through the Site (each, a
            "<strong>Submission</strong>"). Please note, however, that we need
            certain rights to your Submissions to be able to make them available
            on the Site. Accordingly, you hereby grant to us a worldwide,
            non-exclusive, transferable, sublicenseable (through multiple
            tiers), royalty-free, perpetual, irrevocable right and license,
            without compensation to you: to use, reproduce, distribute, adapt
            (including edit, modify, translate, and reformat), create derivative
            works of, transmit, publicly display, publicly perform and otherwise
            exploit such Submission, in any media now known or hereafter
            developed. For each Submission, you represent and warrant that you
            have all rights necessary for you to grant the licenses granted in
            this section, and that such Submission, and your provision thereof
            to and through the Site, comply with all applicable laws, rules and
            regulations. Further, to the extent permitted under applicable law,
            you irrevocably waive any "moral rights" or other rights with
            respect to attribution of authorship or integrity of materials
            regarding each Submission that you may have under any applicable law
            under any legal theory. We requeststhis waiver to help ensure that
            we have all the rights it may need to provide the Site Services
            available through the Site. In addition, we have no control over,
            and shall have no liability for, any damages resulting from the use
            (including republication) or misuse by any third party of
            information voluntarily made public through any other part of the
            Site. IF YOU CHOOSE TO MAKE ANY OF YOUR PERSONALLY IDENTIFIABLE OR
            OTHER INFORMATION PUBLICLY AVAILABLE IN A SUBMISSION, YOU DO SO AT
            YOUR OWN RISK. You acknowledge and agree that we reserve the right
            (but have no obligation) to do any or all of the following, at its
            sole discretion: (i) monitor, evaluate or alter Submissions before
            or after they appear on the Site (including through the use of
            automated filtering software); and (ii) refuse, reject or remove any
            Submission at any time or for any reason (including through the use
            of automated filtering software or if we determine, in its sole
            discretion, that all rights, consents, releases and permissions have
            not been obtained by you despite your representations above).</a
          >
          </li>
          <a name="10" class="nocolor"> </a>
          <li>
            <a name="10" class="nocolor"><strong></strong></a
            ><strong><a name="11" class="nocolor">Proprietary Rights.</a></strong
          ><a name="11" class="nocolor">
            All of the material included on the Site, including trademarks,
            text, graphics, logos and service marks, is the property of ours and
            other parties. U.S. and international copyright laws protects all
            Site Content available on the Site. Your use of the Site Services
            and the Site Content does not transfer to you any ownership or other
            rights in the Site Services or the Site Content. You may download
            Site Content displayed on the Site for your personal, noncommercial
            use only, provided you also retain all copyright and other
            proprietary notices contained on the Site Content. You may not
            modify, copy, distribute, transmit, display, publish, sell, license,
            or create derivative works using any of the Site Content for
            commercial or public purposes.</a
          >
          </li>
          <a name="11" class="nocolor"> </a>
          <li>
            <a name="11" class="nocolor"><strong></strong></a
            ><strong
          ><a name="12" class="nocolor"
          >Consent to Electronic Communications.</a
          ></strong
          ><a name="12" class="nocolor">
            When you visit our siteor send email to us, you are communicating
            with us electronically. You consent to receive communications from
            us electronically. Although we may choose to communicate with you by
            regular mail, we may also choose to communicate with you by email or
            by posting notices on the Site. You agree that all agreements,
            notices, disclosures and other communications that we provide to you
            electronically satisfy any legal requirement that such
            communications be in writing.<br /><br />If you submit a request to
            be matched with one of our third party lenders, please note that
            these third party lenders are required by law to provide you with
            certain communications, notices, disclosures, information and other
            materials ("Communications"). These third party lenders must obtain
            your consent in order to provide you with these Communications
            electronically. By submitting a request to be matched with one of
            our third party lenders, you affirmatively consent and agree to
            receive all Communications required under law electronically in
            accordance with the terms of </a
          ><a href="#">PRIVACY POLICY</a>, OR AS TO THE ACCURACY OR RELIABILITY
            OF ANY INFORMATION OBTAINED THROUGH THE SITE OR SITE SERVICES. ANY
            MATERIAL OR DATA DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
            THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY
            RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA
            THAT RESULTS FROM THE DOWNLOAD OF SUCH MATERIAL OR DATA. WE AND THE
            SERVICE PROVIDERS MAKE NO WARRANTY REGARDING ANY GOODS OR SERVICES
            PURCHASED OR OBTAINED THROUGH THE SITE OR ANY TRANSACTIONS ENTERED
            INTO THROUGH THE SITE AND ARE NOT RESPONSIBLE FOR ANY USE OF
            CONFIDENTIAL OR PRIVATE INFORMATION BY MERCHANTS SELLING THROUGH THE
            SITE OR OTHER USERS. NO ADVICE OR INFORMATION, WHETHER ORAL OR
            WRITTEN, OBTAINED BY YOU FROM US, AFFILIATES OR THROUGH THE SITE,
            SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. SOME
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES, SO
            SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.<br /><br />While we
            take commercially reasonable steps to safeguard and to prevent
            unauthorized access to your information and registration data, we
            cannot be responsible for the acts of those who gain unauthorized
            access, and we make no warranty, express, implied, or otherwise, that
            we will prevent unauthorized access to your private information. IN NO
            EVENT SHALL WE BE LIABLE FOR ANY DAMAGES (WHETHER CONSEQUENTIAL,
            DIRECT, INCIDENTAL, INDIRECT, PUNITIVE, SPECIAL OR OTHERWISE) ARISING
            OUT OF, OR IN ANY WAY CONNECTED WITH, A THIRD PARTY'S UNAUTHORIZED
            ACCESS TO YOUR INFORMATION OR REGISTRATION DATA, REGARDLESS OF WHETHER
            SUCH DAMAGES ARE BASED ON CONTRACT, STRICT LIABILITY, TORT OR OTHER
            THEORIES OF LIABILITY, AND ALSO REGARDLESS OF WHETHER WE WERE GIVEN
            ACTUAL OR CONSTRUCTIVE NOTICE THAT DAMAGES WERE POSSIBLE.
          </li>
          <li>
            <strong
            ><a name="13" class="nocolor">LIMITATION OF LIABILITY.</a></strong
            ><a name="14" class="nocolor">
            WE AND THE SERVICE PROVIDERS SHALL NOT BE LIABLE FOR ANY INDIRECT,
            INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES RESULTING FROM THE USE
            OR THE INABILITY TO USE THE SITE OR FOR COST OF PROCUREMENT OF
            SUBSTITUTE GOODS AND SERVICES OR RESULTING FROM ANY GOODS OR
            SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS
            ENTERED INTO THROUGH THE SITE OR RESULTING FROM UNAUTHORIZED ACCESS
            TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA, INCLUDING DAMAGES
            FOR LOSS OF PROFITS, USE, DATA OR OTHER INTANGIBLE, EVEN IF WE OR
            THE SERVICE PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION
            OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME OF THE
            ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN NO EVENT WILL WE AND THE
            SERVICE PROVIDERS EVER BE LIABLE TO YOU OR ANY OTHER PERSON FOR ANY
            CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES, AND
            COSTS, WHETHER IN CONTRACT, TORT OR UNDER OTHER FAULT OR WRONGDOING
            BY WE AND THE SERVICE PROVIDERS OR ANY PERSON FOR WHOM EITHER US OR
            THE SERVICE PROVIDERS ARE RESPONSIBLE, IN AN AMOUNT EXCEEDING THE
            AMOUNT YOU PAID TO US FOR THE USE OF THE SITE WHICH IS THE SUBJECT
            OF THE CLAIM.</a
          >
          </li>
          <a name="14" class="nocolor"> </a>
          <li>
            <a name="14" class="nocolor"><strong></strong></a
            ><strong><a name="15" class="nocolor">Indemnification.</a></strong
          ><a name="15" class="nocolor">
            You agree to indemnify and hold us, its subsidiaries, affiliates,
            officers and employees, and Service Providers harmless from any
            claim or demand, including reasonable attorneys' fees, made by any
            third party due to or arising out of your use of the Site, the
            violation of this Agreement by you, or the infringement by you, or
            other user of the Site using your computer, of any intellectual
            property or other right of any person or entity. Even though
            prohibited, people may provide information that is offensive, false,
            harmful, or deceptive. We and our Service Providers assume no
            responsibility whatsoever for such content or actions.
          </a>
          </li>
          <a name="15" class="nocolor"> </a>
          <li>
            <a name="15" class="nocolor"><strong></strong></a
            ><strong><a name="16" class="nocolor">RELEASE.</a></strong
          ><a name="16" class="nocolor">
            YOU HEREBY RELEASE, REMISE AND FOREVER DISCHARGE US AND THE SERVICE
            PROVIDERS AND EACH OF THEIR RESPECTIVE AGENTS, DIRECTORS, OFFICERS,
            EMPLOYEES, INFORMATION PROVIDERS, SERVICE PROVIDERS, SUPPLIERS,
            LICENSORS AND LICENSEES, AND ALL OTHER RELATED, ASSOCIATED, OR
            CONNECTED PERSONS FROM ANY AND ALL MANNER OF RIGHTS, CLAIMS,
            COMPLAINTS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS, LIABILITIES,
            OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF ANY NATURE AND
            KIND WHATSOEVER AND HOWSOEVER ARISING, WHETHER KNOWN OR UNKNOWN,
            WHICH NOW OR HEREAFTER EXIST, WHICH ARISE FROM, RELATE TO, OR ARE
            CONNECTED WITH YOUR USE OF THE SITE.</a
          >
          </li>
          <a name="16" class="nocolor"> </a>
          <li>
            <a name="16" class="nocolor"><strong></strong></a
            ><strong><a name="17" class="nocolor">ARBITRATON.</a></strong
          ><a name="17" class="nocolor">
            <p>
              <strong
              >THIS AGREEMENT PROVIDES THAT ALL DISPUTES BETWEEN YOU AND US
                WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND NOT IN
                COURT OR BY JURY TRIAL. IF A DISPUTE IS ARBITRATED, YOU GIVE UP
                YOUR RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR CLASS
                MEMBER ON ANY CLASS CLAIM YOU MAY HAVE AGAINST US INCLUDING ANY
                RIGHT TO CLASS ARBITRATION OR ANY CONSOLIDATION OF INDIVIDUAL
                ARBITRATIONS. DISCOVERY AND RIGHTS TO APPEAL IN ARBITRATION ARE
                GENERALLY MORE LIMITED THAN IN A LAWSUIT, AND OTHER RIGHTS
                AVAILABLE IN COURT MAY NOT BE AVAILABLE IN ARBITRATION.</strong
              >
            </p>
            <p>
              Except if you opt-out or for disputes relating to: (i) yours or
              our intellectual property (such as trademarks, trade dress, domain
              names, trade secrets, copyrights and patents); or (ii) violations
              of provisions 6.d or 6.i of this Agreement, you agree that all
              disputes between you and us (whether or not such dispute involves
              a third party) with regard to your relationship with the Site,
              including disputes related to this Agreement, your use of the
              Site, and/or rights of privacy and/or publicity, will be resolved
              by binding, individual arbitration under the American Arbitration
              Association's rules for arbitration of consumer-related disputes
              and you and us hereby expressly waive trial by jury. As an
              alternative, you may bring your claim in your local "small claims"
              court, if permitted by that small claims court's rules. You may
              bring claims only on your own behalf. Neither you nor us will
              participate in a class action or class-wide arbitration for any
              claims covered by this Agreement. You also agree not to
              participate in claims brought in a private attorney general or
              representative capacity, or consolidated claims involving another
              person's account, if we is a party to the proceeding. This dispute
              resolution provision will be governed by the Federal Arbitration
              Act. In the event the American Arbitration Association is
              unwilling or unable to set a hearing date within one hundred and
              sixty (160) days of filing the case, then either us or you can
              elect to have the arbitration administered instead by the Judicial
              Arbitration and Mediation Services. Judgment on the award rendered
              by the arbitrator may be entered in any court having competent
              jurisdiction. Any provision of applicable law notwithstanding, the
              arbitrator will not have authority to award damages, remedies or
              awards that conflict with this Agreement.
            </p>
            <p>
              You may opt out of this agreement to arbitrate. If you do so,
              neither you nor us can require the other to participate in an
              arbitration proceeding. To opt out, you must notify us in writing
              within 30 days of the date that you first became subject to this
              arbitration provision. You must use this address to opt out:
            </p>
            <p>
              DRT Debt Reduction Team<br />
              ATTN: Legal - Arbitration Opt-out<br />
              36 Rosscragon Rd Suite F<br />
              Asheville, NC 2883<br />
            </p>

            <p>
              You must include your name and residence address, the email
              address you used, and a clear statement that you want to opt out
              of this arbitration agreement.
            </p>
            <p>
              If the prohibition against class actions and other claims brought
              on behalf of third parties contained above is found to be
              unenforceable, then all of the preceding language in this
              Arbitration Clause will be null and void. This arbitration
              agreement will survive the termination of your relationship with
              us.
            </p>
            <p>
              In any arbitration, we will pay the filing, administration,
              service or case management fee, plus the costs associated with the
              first day of arbitration, with the remaining costs to be paid by
              the non-prevailing party. We will pay additional arbitration
              expenses to the extent that the arbitrator determines that we must
              do so in order to ensure the enforceability of this Arbitration
              Clause. Each party shall be responsible for its own attorney,
              expert and other fees, unless awarded by the arbitrator under
              applicable law.
            </p>
            <p>
              The arbitrator's award is final and binding on all parties, except
              that the losing party may request a new arbitration under the
              rules of the arbitration organization by a three-arbitrator panel.
            </p>
          </a>
          </li>
          <a name="17" class="nocolor"> </a>
          <li>
            <a name="17" class="nocolor"><strong></strong></a
            ><strong
          ><a name="18" class="nocolor"
          >Claims of Copyright Infringement.</a
          ></strong
          ><a name="18" class="nocolor">
            The Digital Millennium Copyright Act of 1998 (the "DMCA") provides
            recourse for copyright owners who believe that material appearing on
            the Internet infringes their rights under U.S. copyright law. If you
            believe in good faith that materials available on the Site infringe
            your copyright, you (or your agent) may send us a notice requesting
            that we remove the material or block access to it. If you believe in
            good faith that someone has wrongly filed a notice of copyright
            infringement against you, the DMCA permits you to send us a
            counter-notice. Notices and counter-notices must meet the
            then-current statutory requirements imposed by the DMCA. See </a
          ><a href="http://www.copyright.gov/">http://www.copyright.gov/</a> for
            details. Notices and counter-notices should be sent to:
            <p>
              DRT Debt Reduction Team<br />
              ATTN: Legal - Arbitration Opt-out<br />
              36 Rosscragon Rd Suite F<br />
              Asheville, NC 2883<br />
            </p>

            <p>
              We suggest that you consult your legal advisor before submitting a
              notice or counter-notice.
            </p>
          </li>
          <li>
            <strong><a name="19" class="nocolor">Governing Law.</a></strong
            ><a name="19" class="nocolor">
            The Agreement shall be governed by and construed in accordance with
            the laws of the State of Georgia (without regard to conflict of law
            provisions thereof). If any provision(s) of the Agreement is held
            contrary to law, then such provision(s) shall be construed, as
            nearly as possible, to reflect the intentions of the parties with
            the other provisions remaining in full force and effect. The failure
            of us to exercise or enforce any right or provision of the Agreement
            shall not constitute a waiver of such right or provision unless
            acknowledged and agreed to by us in writing. The section titles in
            the Agreement are used solely for the convenience of the parties and
            have no legal or contractual significance.</a
          >
          </li>
          <a name="19" class="nocolor"> </a>
        </ol>
        <a name="19" class="nocolor">
          <p></p>
          <p>
            I expressly authorize the third party lender I am matched with to
            share among its affiliates, loan servicers, and bank partners any
            transaction history related to my financial products or services
            received or serviced through the third party lender for the purpose of
            evaluating me for credit.
          </p>
        </a>
      </div>
      <a name="19" class="nocolor"> </a>
      <div id="close-bottom">
        <a name="19" class="nocolor"></a
        ><a class="link" href="javascript:self.close();" title="Close"
      >Close this window</a
      >
      </div>
    </div>
  </div>
</template>

<style scoped>
body {
  background-color: #ffffff;
  margin-top: 0px;
  margin-right: auto;
  margin-left: auto;
  font-family: Arial, Sans, Helvitica, sans-serif;
  font-style: normal;
  color: #333333;
}
#container {
  float: auto;
  background-color: #ffffff;
  position: relative;
  margin: 0 auto;
  overflow: auto;
  width: 1000px;
}
#close-top {
  float: right;
  padding: 5px 50px 0px 0px;
}
#close-bottom {
  float: right;
  padding: 0px 50px 5px 0px;
}
#header {
  text-align: left;
  padding: 0 0 10px 100px;
  border-bottom: 1px solid #cccccc;
}
#container_wrapper {
  padding: 0px 20px 10px 20px;
}
.link {
  font-size: 11px;
  font-weight: normal;
}
#info {
  padding: 20px 100px 0 100px;
}
h1 {
  font-size: 23px;
  font-weight: normal;
}
h2 {
  font-size: 12px;
  font-weight: normal;
  color: #999999;
}
p {
  font-size: 12px;
  font-weight: normal;
}
#info a {
  color: blue;
}
.nocolor {
  color: #333333 !important;
}
#info ol {
  padding-left: 20px;
}
#info li {
  font-size: 12px;
  margin: 0 0 20px 0;
}
#info li li {
  font-size: 12px;
  margin: 0;
}
#info ul li {
  font-size: 12px;
  margin: 0;
}
</style>